<template>
  <div class="page-content">
    <div class="container text-center ">
      <img src="/static/images/svgs/404.svg" alt="img" class="w-30 mb-6">
      <h1 class="h3  mb-3 font-weight-bold">Sorry, an error has occured, Requested Page not found!</h1>
      <p class="h5 font-weight-normal mb-7 leading-normal">You may have mistyped the address or the page may have moved.</p>
      <router-link class="btn btn-primary" to="/"><i class="fe fe-arrow-left-circle mr-1"></i>Back to Home</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Error404',
};
</script>

<style scoped>

</style>
